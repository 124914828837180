import React from 'react';
import { Grid } from '@material-ui/core';
import { InfoCard } from '@backstage/core-components';
import { LikeDislikeButtons } from '@backstage-community/plugin-entity-feedback';
import {
  EntityHasSubcomponentsCard,
  EntityLinksCard
} from '@backstage/plugin-catalog';
import { entityWarningContent } from './EntityWarningContent';
import { EntityHasToolsCard, EntityAboutCard } from '@internal/plugin-catalog';
// import { EntityAboutCard } from '@backstage/plugin-catalog';
import { EntityCatalogGraphCard } from '@backstage/plugin-catalog-graph';
import { EntitySonarQubeCard } from '@backstage-community/plugin-sonarqube';
import { EntityLinguistCard } from '@backstage-community/plugin-linguist';

export const componentOverviewContent = (
  <Grid container spacing={2} alignItems="stretch">
    <Grid item xs={12}>
      {entityWarningContent}
    </Grid>
    <Grid item md={6}>
      <EntityAboutCard variant="gridItem" />
    </Grid>
    <Grid item md={6} xs={12}>
      <EntityCatalogGraphCard variant="gridItem" height={400} />
    </Grid>
    <Grid item md={6}>
      <EntitySonarQubeCard variant="gridItem" />
    </Grid>
    <Grid item md={6}>
      <Grid container direction="column" spacing={1}>
        <Grid item xs={12}>
          <EntityLinguistCard />
        </Grid>
        <Grid container direction="row" spacing={1}>
          <Grid item xs={6}>
            <EntityLinksCard />
          </Grid>
          <Grid item xs={6}>
            <InfoCard title="Rate this entity">
              <LikeDislikeButtons />
            </InfoCard>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
    <Grid item md={6} xs={12}>
      <EntityHasSubcomponentsCard variant="gridItem" />
    </Grid>
    <Grid item md={6} xs={8}>
      <EntityHasToolsCard variant="gridItem" />
    </Grid>
  </Grid>
);
