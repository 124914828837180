import { OAuth2, OAuthApiCreateOptions } from '@backstage/core-app-api';
import { adoAuthApiRef } from '@internal/apis';

const DEFAULT_PROVIDER = {
  id: 'ado',
  title: 'Azure DevOps',
  icon: () => null,
};

/**
 * Implements the OAuth flow to Azure DevOps products.
 *
 * @public
 */
export default class AdoAuth {
  static create(options: OAuthApiCreateOptions): typeof adoAuthApiRef.T {
    const {
      configApi,
      discoveryApi,
      environment = 'development',
      provider = DEFAULT_PROVIDER,
      oauthRequestApi,
      defaultScopes = [
        'vso.build_execute',
        'vso.code_full',
        'vso.identity_manage',
        'vso.profile_write',
        'vso.work_full',
      ],
    } = options;
    return OAuth2.create({
      configApi,
      discoveryApi,
      oauthRequestApi,
      provider,
      environment,
      defaultScopes,
    });
  }
}
