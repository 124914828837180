import React from 'react';
import { Grid } from '@material-ui/core';
import { InfoCard } from '@backstage/core-components';
import { LikeDislikeButtons } from '@backstage-community/plugin-entity-feedback';
import {
  EntityLinksCard,
  EntityDependsOnResourcesCard,
} from '@backstage/plugin-catalog';
import { entityWarningContent } from './EntityWarningContent';
import { EntityAboutCard } from '@internal/plugin-catalog';
import { EntityCatalogGraphCard } from '@backstage/plugin-catalog-graph';

export const resourceOverviewContent = (
  <Grid container spacing={3} alignItems="stretch">
    <Grid item xs={12}>
      {entityWarningContent}
    </Grid>
    <Grid item md={6}>
      <EntityAboutCard variant="gridItem" />
    </Grid>
    <Grid item md={6} xs={12}>
      <EntityCatalogGraphCard variant="gridItem" height={400} />
    </Grid>
    <Grid item md={4} xs={4}>
      <EntityLinksCard />
    </Grid>
    <Grid item md={2}>
      <InfoCard title="Rate this entity">
        <LikeDislikeButtons />
      </InfoCard>
    </Grid>
    <Grid item md={6} xs={8}>
      <EntityDependsOnResourcesCard variant="gridItem" />
    </Grid>
  </Grid>
);
