import React from 'react';
import {
  WelcomeTitle,
  HeaderWorldClock,
  ClockConfig,
} from '@backstage/plugin-home';
import { Content, Header, Page } from '@backstage/core-components';
import { Grid, Typography } from '@material-ui/core';
import { CustomizableGrid } from './CustomizableGrid';

const clockConfigs: ClockConfig[] = [
  {
    label: 'NYC',
    timeZone: 'America/New_York',
  },
  {
    label: 'SPO',
    timeZone: 'America/Sao_Paulo',
  },
  {
    label: 'UTC',
    timeZone: 'UTC',
  },
  {
    label: 'LON',
    timeZone: 'Europe/London',
  },
  {
    label: 'TYO',
    timeZone: 'Asia/Tokyo',
  },
];

const timeFormat: Intl.DateTimeFormatOptions = {
  hour: '2-digit',
  minute: '2-digit',
  hour12: false,
};

export const homePage = (
  <Page themeId="home">
    <Header title="TOTVSApps Platform Portal" pageTitleOverride="Home">
      <HeaderWorldClock
        clockConfigs={clockConfigs}
        customTimeFormat={timeFormat}
      />
    </Header>
    <Content>
      <Grid container spacing={3} alignItems="stretch">
        <Grid item md={12}>
          <Typography variant="h5">
            <WelcomeTitle />
          </Typography>
        </Grid>
      </Grid>
      <CustomizableGrid />
    </Content>
  </Page>
);
