import React from 'react';
import { makeStyles } from '@material-ui/core';
import TOTVSLogoIcon from './logo/faviconV2.jpeg';

interface LogoIconProps {
  width?: string;
}

const useStyles = makeStyles({
  image: {
    // width: (props: LogoIconProps) => props.width || '150px',
    // height: 'auto',
    width: 'auto',
    height: 28,
  },
});

const LogoIcon: React.FC<LogoIconProps> = props => {
  const classes = useStyles(props);
  // Provide a meaningful description for the logo image.
  // If the logo is purely decorative and not crucial to understanding the page, you can use alt="" instead.
  return <img src={TOTVSLogoIcon} alt="TOTVS Logo" className={classes.image} />;
};

export default LogoIcon;
