import {
  createComponentExtension,
  createPlugin,
} from '@backstage/core-plugin-api';
import { AboutCardProps } from './components/AboutCard/AboutCard';
import { DependencyOfToolsCardProps } from './components/DependencyOfToolsCard/DependencyOfToolsCard';
import { DependsOnToolsCardProps } from './components/DependsOnToolsCard/DependsOnToolsCard';
import { HasToolsCardProps } from './components/HasToolsCard/HasToolsCard';

import { rootRouteRef } from './routes';
import { EntityLinksCardProps } from './components/EntityLinksCard';

export const catalogPlugin = createPlugin({
  id: 'totvsapps-catalog',
  routes: {
    root: rootRouteRef,
  },
});

export const EntityAboutCard: (props: AboutCardProps) => JSX.Element =
  catalogPlugin.provide(
    createComponentExtension({
      name: 'EntityAboutCard',
      component: {
        lazy: () => import('./components/AboutCard').then(m => m.AboutCard),
      },
    }),
  );

export const EntityLinksCard: (props: EntityLinksCardProps) => JSX.Element =
  catalogPlugin.provide(
    createComponentExtension({
      name: 'EntityLinksCard',
      component: {
        lazy: () =>
          import('./components/EntityLinksCard').then(m => m.EntityLinksCard),
      },
    }),
  );

export const EntityHasToolsCard: (props: HasToolsCardProps) => JSX.Element =
  catalogPlugin.provide(
    createComponentExtension({
      name: 'EntityHasToolsCard',
      component: {
        lazy: () =>
          import('./components/HasToolsCard').then(m => m.HasToolsCard),
      },
    }),
  );

export const EntityDependsOnToolsCard: (
  props: DependsOnToolsCardProps,
) => JSX.Element = catalogPlugin.provide(
  createComponentExtension({
    name: 'EntityDependsOnToolsCard',
    component: {
      lazy: () =>
        import('./components/DependsOnToolsCard').then(
          m => m.DependsOnToolsCard,
        ),
    },
  }),
);

export const EntityDependencyOfToolsCard: (
  props: DependencyOfToolsCardProps,
) => JSX.Element = catalogPlugin.provide(
  createComponentExtension({
    name: 'EntityDependencyOfToolsCard',
    component: {
      lazy: () =>
        import('./components/DependencyOfToolsCard').then(
          m => m.DependencyOfToolsCard,
        ),
    },
  }),
);
