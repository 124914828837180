import React from 'react';
import { EntityLayout } from '@backstage/plugin-catalog';
import { Grid } from '@material-ui/core';
import { EntityLinksCard } from '@backstage/plugin-catalog';
import { EntityCatalogGraphCard } from '@backstage/plugin-catalog-graph';
import { EntityAboutCard } from '@internal/plugin-catalog';
import { techDocsContent, entityWarningContent } from './contents';

export const toolPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3} alignItems="stretch">
        {entityWarningContent}
        <Grid item md={6} xs={12}>
          <EntityAboutCard variant="gridItem" />
        </Grid>

        <Grid item md={6} xs={12}>
          <EntityCatalogGraphCard
            maxDepth={1}
            variant="gridItem"
            height={400}
          />
        </Grid>

        <Grid item md={12} xs={12}>
          <EntityLinksCard />
        </Grid>
      </Grid>
    </EntityLayout.Route>

    <EntityLayout.Route path="/docs" title="Docs">
      {techDocsContent}
    </EntityLayout.Route>
  </EntityLayout>
);
