import React from 'react';
import { isKind, EntitySwitch } from '@backstage/plugin-catalog';

import {
  apiPage,
  componentPage,
  domainPage,
  groupPage,
  systemPage,
  userPage,
  toolPage,
  defaultPage,
  resourcePage,
} from './children';

export const entityPage = (
  <EntitySwitch>
    <EntitySwitch.Case if={isKind('component')} children={componentPage} />
    <EntitySwitch.Case if={isKind('resource')} children={resourcePage} />
    <EntitySwitch.Case if={isKind('tool')} children={toolPage} />
    <EntitySwitch.Case if={isKind('api')} children={apiPage} />
    <EntitySwitch.Case if={isKind('group')} children={groupPage} />
    <EntitySwitch.Case if={isKind('user')} children={userPage} />
    <EntitySwitch.Case if={isKind('system')} children={systemPage} />
    <EntitySwitch.Case if={isKind('domain')} children={domainPage} />

    <EntitySwitch.Case>{defaultPage}</EntitySwitch.Case>
  </EntitySwitch>
);
