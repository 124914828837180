import React from 'react';
import { EntityLayout } from '@backstage/plugin-catalog';

import { techDocsContent, componentOverviewContent } from './contents';

export const defaultPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      {componentOverviewContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/docs" title="Docs">
      {techDocsContent}
    </EntityLayout.Route>
  </EntityLayout>
);
