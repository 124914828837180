import React, { useEffect, useState } from 'react';
import {
  HomePageRandomJoke,
  HomePageStarredEntities,
  CustomHomepageGrid,
  HomePageToolkit,
} from '@backstage/plugin-home';
import { HomePageSearchBar } from '@backstage/plugin-search';
import { toolkitElements } from './ToolkitElements';
import { makeStyles } from '@material-ui/core';
import { ToolboxHomepageCard } from '@drodil/backstage-plugin-toolbox';

const useStyles = makeStyles(theme => ({
  searchBar: {
    display: 'flex',
    maxWidth: '70vw',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[1],
    padding: '8px 0',
    borderRadius: '50px',
    margin: 'auto',
  },
  searchBarOutline: {
    borderStyle: 'none',
  },
}));

export const CustomizableGrid = () => {
  const classes = useStyles();
  const [isMounted, setIsMounted] = useState(true); // Track component's mounted state

  useEffect(() => {
    // Cleanup function
    return () => {
      setIsMounted(false); // Set the mounted state to false when unmounting
    };
  }, []);

  const defaultConfig = [
    {
      component: <HomePageSearchBar />,
      x: 0,
      y: 0,
      width: 12,
      height: 2,
    },
    {
      component: <HomePageStarredEntities />,
      x: 0,
      y: 6,
      width: 4,
      height: 4,
    },
    {
      component: <HomePageRandomJoke />,
      x: 4,
      y: 6,
      width: 4,
      height: 4,
    },
    {
      component: <HomePageToolkit tools={toolkitElements} />,
      x: 0,
      y: 8,
      width: 8,
      height: 4,
    },
  ];

  if (!isMounted) {
    return null; // Return null if the component is unmounted
  }

  return (
    <CustomHomepageGrid
      config={defaultConfig}
      // cols={{ xl: 12, lg: 12, md: 10, sm: 6, xs: 4 }}
      // breakpoints={{ xl: 12, lg: 12, md: 10, sm: 6, xs: 4 }}
    >
      <HomePageSearchBar
        classes={{ root: classes.searchBar }}
        InputProps={{ classes: { notchedOutline: classes.searchBarOutline } }}
        placeholder="Search"
      />
      <HomePageRandomJoke />
      <HomePageStarredEntities />
      <HomePageToolkit tools={toolkitElements} />
      <ToolboxHomepageCard />
    </CustomHomepageGrid>
  );
};
