import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  svg: {
    width: 'auto',
    height: 50,
  },
  path: {
    fill: '#666666',
  },
});

const LogoFull = () => {
  const classes = useStyles();

  return (
    <svg
      id="Camada_1"
      data-name="Camada 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 95.28 27.9"
      className={classes.svg}
    >
      <path
        id="Fill-2"
        className={classes.path}
        d="M44.53,9.13H41.19v12a.41.41,0,0,1-.41.41h-2.7a.41.41,0,0,1-.41-.41v-12H34.31a.39.39,0,0,1-.44-.34V6.62a.38.38,0,0,1,.39-.39H44.53a.42.42,0,0,1,.44.39V8.74a.42.42,0,0,1-.44.39"
      />
      <path
        id="Fill-4"
        className={classes.path}
        d="M54.45,12.65c0-2.47-.83-3.52-2.56-3.52s-2.53,1-2.53,3.52V15.1c0,2.47.8,3.52,2.53,3.52s2.56-1.05,2.56-3.52Zm-2.58,9.06c-3.4,0-6.1-1.83-6.1-6.61V12.65c0-4.77,2.7-6.61,6.1-6.61S58,7.88,58,12.65V15.1C58,19.88,55.29,21.71,51.87,21.71Z"
      />
      <path
        id="Fill-6"
        className={classes.path}
        d="M69.41,9.13H66.07v12a.41.41,0,0,1-.41.41H63a.41.41,0,0,1-.41-.41v-12H59.19a.4.4,0,0,1-.42-.38h0V6.62a.4.4,0,0,1,.41-.39H69.41a.41.41,0,0,1,.43.39h0V8.74a.41.41,0,0,1-.43.39h0"
      />
      <path
        id="Fill-8"
        className={classes.path}
        d="M83.87,6.66l-3,12.76c-.47,2-2,2.29-3.4,2.29s-3-.31-3.42-2.29L70.94,6.66v-.1a.33.33,0,0,1,.35-.33h2.88a.45.45,0,0,1,.42.37l2.55,11.88c0,.22.11.31.29.31s.25-.09.29-.31L80.3,6.6a.42.42,0,0,1,.39-.37h2.88a.32.32,0,0,1,.35.29v.14"
      />
      <path
        id="Fill-10"
        className={classes.path}
        d="M89.72,21.71a16,16,0,0,1-3.9-.45c-.21-.07-.41-.19-.41-.42v-2a.34.34,0,0,1,.28-.39h.18c.85.08,3.11.24,3.92.24,1.42,0,1.83-.45,1.83-1.44,0-.6-.29-.92-1.36-1.56L87.39,14A4.2,4.2,0,0,1,85,10.05c0-2.27,1.27-4,5.27-4a22.13,22.13,0,0,1,4.37.46.35.35,0,0,1,.31.37V9a.31.31,0,0,1-.26.35h-.09c-.58,0-3-.23-4.37-.23-1.18,0-1.61.35-1.61,1.09,0,.58.27.84,1.38,1.44L92.56,13c2.15,1.15,2.78,2.51,2.78,4.18,0,2.18-1.44,4.51-5.62,4.51"
      />
      <path
        id="Fill-12"
        className={classes.path}
        d="M17.94,11.91c-.53-.46-4.88-1.68-8.5-2.54,0,2.79.14,5.82.51,6.35.53.45,4.89,1.68,8.51,2.54,0-2.79-.15-5.83-.52-6.35"
      />
      <path
        id="Fill-14"
        className={classes.path}
        d="M23.38,19.11a22.14,22.14,0,0,1-4.92-.85c0,2.39-.2,4.61-.52,4.91-.89.31-12.53-2.63-13.42-3.39-.65-1-.65-10.63,0-11.26a22.14,22.14,0,0,1,4.92.85c0-2.4.2-4.62.52-4.91.91-.31,12.53,2.63,13.42,3.39.69,1,.69,10.63,0,11.26M14,0A14,14,0,1,0,28,14,14,14,0,0,0,14,0"
      />
    </svg>
  );
};

export default LogoFull;
