import {
  ScmIntegrationsApi,
  scmIntegrationsApiRef,
  ScmAuth,
  scmAuthApiRef,
} from '@backstage/integration-react';
import {
  analyticsApiRef,
  identityApiRef,
  AnyApiFactory,
  configApiRef,
  createApiFactory,
  discoveryApiRef,
  oauthRequestApiRef,
} from '@backstage/core-plugin-api';
import { GoogleAnalytics } from '@backstage-community/plugin-analytics-module-ga';

// Custom implementation of AzureDevOps OAuth2Ref
import { AdoAuth } from '@internal/core-app-api';
import { adoAuthApiRef, fluigAuthApiRef } from '@internal/apis';
import { OAuth2 } from '@backstage/core-app-api';

export const apis: AnyApiFactory[] = [
  createApiFactory({
    api: fluigAuthApiRef,
    deps: {
      discoveryApi: discoveryApiRef,
      oauthRequestApi: oauthRequestApiRef,
      configApi: configApiRef,
    },
    factory: ({ discoveryApi, oauthRequestApi, configApi }) =>
      OAuth2.create({
        discoveryApi,
        oauthRequestApi,
        environment: configApi.getOptionalString('auth.environment'),
      }),
  }),
  createApiFactory({
    api: adoAuthApiRef,
    deps: {
      discoveryApi: discoveryApiRef,
      oauthRequestApi: oauthRequestApiRef,
      configApi: configApiRef,
    },
    factory: ({ discoveryApi, oauthRequestApi, configApi }) =>
      AdoAuth.create({
        configApi,
        discoveryApi,
        oauthRequestApi,
        defaultScopes: [],
        environment: configApi.getOptionalString('auth.environment'),
      }),
  }),
  createApiFactory({
    api: scmAuthApiRef,
    deps: {
      adoAuthApi: adoAuthApiRef,
    },
    factory: ({ adoAuthApi }) =>
      ScmAuth.merge(
        ScmAuth.forAuthApi(adoAuthApi, {
          host: 'dev.azure.com',
          scopeMapping: {
            default: [],
            repoWrite: [],
          },
        }),
      ),
  }),
  createApiFactory({
    api: analyticsApiRef,
    deps: { configApi: configApiRef, identityApi: identityApiRef },
    factory: ({ configApi, identityApi }) =>
      GoogleAnalytics.fromConfig(configApi, {
        identityApi,
      }),
  }),
  createApiFactory({
    api: scmIntegrationsApiRef,
    deps: { configApi: configApiRef },
    factory: ({ configApi }) => ScmIntegrationsApi.fromConfig(configApi),
  }),
];
