import React from 'react';
import { EntityLayout } from '@backstage/plugin-catalog';
import { techDocsContent, resourceOverviewContent } from './contents';

export const resourcePage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      {resourceOverviewContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/docs" title="Docs">
      {techDocsContent}
    </EntityLayout.Route>
  </EntityLayout>
);
